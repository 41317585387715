import { Icon, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ISplitRow, ISplitRowProps } from "../interfaces";

const i18_PATH = "prodotto.view.lottoDettagli.dialog.split.";

function SplitRow({
  availableQuantity,
  row,
  removeRow,
  updateRow,
}: ISplitRowProps) {
  const { t } = useTranslation();

  const maxLastraA = useMemo(
    () => (row.lastreDa ?? 0) + (row.quantity ?? 0) + availableQuantity - 2,
    [availableQuantity, row]
  );

  const onLastraAChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let lastreA = !isNaN(parseInt(value)) ? parseInt(value) : null;
    let updatedRow: ISplitRow;
    if (lastreA) {
      if (lastreA && lastreA < (row.lastreDa ?? 0)) lastreA = row.lastreDa ?? 0;
      if (lastreA && lastreA > maxLastraA) lastreA = maxLastraA;
      const quantity = lastreA - (row.lastreDa ?? 0) + 1;
      updatedRow = { ...row, lastreA, quantity };
      updateRow(updatedRow);
    } else {
      updatedRow = { ...row, lastreA };
    }
    updateRow(updatedRow);
  };

  const onMisXChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let misX = !!value ? parseInt(value) : null;
    if (misX && misX < 0) misX = 0;
    const updatedRow: ISplitRow = { ...row, misX };
    updateRow(updatedRow);
  };

  const onMisYChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let misY = !!value ? parseInt(value) : null;
    if (misY && misY < 0) misY = 0;
    const updatedRow: ISplitRow = { ...row, misY };
    updateRow(updatedRow);
  };

  const onMisZChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let misZ = !!value ? parseInt(value) : null;
    if (misZ && misZ < 0) misZ = 0;
    const updatedRow: ISplitRow = { ...row, misZ };
    updateRow(updatedRow);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <TextField
        margin="dense"
        label={t(`${i18_PATH}LastreDa`)}
        type="number"
        value={row.lastreDa}
        disabled={true}
        sx={{ flex: 1 }}
      />
      <TextField
        margin="dense"
        label={t(`${i18_PATH}LastreA`)}
        type="number"
        value={row.lastreA}
        onChange={onLastraAChange}
        sx={{ flex: 1 }}
      />
      <TextField
        margin="dense"
        label={t(`${i18_PATH}Quantity`)}
        type="number"
        value={row.quantity}
        disabled={true}
        sx={{ flex: 1 }}
      />
      <TextField
        margin="dense"
        label={t(`${i18_PATH}MisX`)}
        type="number"
        value={row.misX}
        onChange={onMisXChange}
        sx={{ flex: 1 }}
      />
      <TextField
        margin="dense"
        label={t(`${i18_PATH}MisY`)}
        type="number"
        value={row.misY}
        onChange={onMisYChange}
        sx={{ flex: 1 }}
      />
      <TextField
        margin="dense"
        label={t(`${i18_PATH}MisZ`)}
        type="number"
        value={row.misZ}
        onChange={onMisZChange}
        sx={{ flex: 1 }}
      />
      <Box sx={{ p: 2 }}>
        <IconButton onClick={removeRow}>
          <Icon
            className="fa-light fa-trash"
            fontSize="small"
            color="error"
            sx={{ overflow: "visible" }}
          />
        </IconButton>
      </Box>
    </Box>
  );
}

export default SplitRow;
