import { Icon, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ISplitLastRowProps, ISplitRow } from "../interfaces";

const i18_PATH = "prodotto.view.lottoDettagli.dialog.split.";

const checkValidity = (row: ISplitRow): boolean =>
  row.quantity !== null &&
  row.quantity > 1 &&
  !!row.lastreDa &&
  !!row.lastreA &&
  row.quantity === row.lastreA - row.lastreDa + 1;

function SplitLastRow({
  row,
  splitted,
  addRow,
  updateRow,
}: ISplitLastRowProps) {
  const { t } = useTranslation();

  const isValid: boolean = useMemo(() => checkValidity(row), [row]);

  const onLastraDaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let lastreDa = !!value ? parseInt(value) : null
    if (!!lastreDa && lastreDa < 0) lastreDa = 0;
    const updatedRow: ISplitRow = { ...row, lastreDa };
    updateRow(updatedRow);
  };

  const onLastraAChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let lastreA = !!value ? parseInt(value) : null;
    if (lastreA && lastreA < 0) lastreA = 0;
    const updatedRow: ISplitRow = { ...row, lastreA };
    updateRow(updatedRow);
  };

  const onMisXChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let misX = !!value ? parseInt(value) : null;
    if (misX && misX < 0) misX = 0;
    const updatedRow: ISplitRow = { ...row, misX };
    updateRow(updatedRow);
  };

  const onMisYChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let misY = !!value ? parseInt(value) : null;
    if (misY && misY < 0) misY = 0;
    const updatedRow: ISplitRow = { ...row, misY };
    updateRow(updatedRow);
  };

  const onMisZChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let misZ = !!value ? parseInt(value) : null;
    if (misZ && misZ < 0) misZ = 0;
    const updatedRow = { ...row, misZ };
    updateRow(updatedRow);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <TextField
        margin="dense"
        label={t(`${i18_PATH}LastreDa`)}
        type="number"
        value={row.lastreDa}
        onChange={onLastraDaChange}
        disabled={splitted}
        sx={{ flex: 1 }}
      />
      <TextField
        margin="dense"
        label={t(`${i18_PATH}LastreA`)}
        type="number"
        value={row.lastreA}
        onChange={onLastraAChange}
        disabled={splitted}
        sx={{ flex: 1 }}
      />
      <TextField
        margin="dense"
        label={t(`${i18_PATH}Quantity`)}
        type="number"
        value={`${row.quantity}`}
        disabled={true}
        sx={{ flex: 1 }}
      />
      <TextField
        margin="dense"
        label={t(`${i18_PATH}MisX`)}
        type="number"
        value={row.misX}
        onChange={onMisXChange}
        sx={{ flex: 1 }}
      />
      <TextField
        margin="dense"
        label={t(`${i18_PATH}MisY`)}
        type="number"
        value={row.misY}
        onChange={onMisYChange}
        sx={{ flex: 1 }}
      />
      <TextField
        margin="dense"
        label={t(`${i18_PATH}MisZ`)}
        type="number"
        value={row.misZ}
        onChange={onMisZChange}
        sx={{ flex: 1 }}
      />
      <Box sx={{ p: 2 }}>
        <IconButton onClick={addRow} color="primary" disabled={!isValid}>
          <Icon
            className="fa-light fa-split"
            fontSize="small"
            sx={{ overflow: "visible" }}
          />
        </IconButton>
      </Box>
    </Box>
  );
}

export default SplitLastRow;
