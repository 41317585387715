import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  Divider,
  Icon,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDettaglioSplit } from "../../../../../../../effects/dettaglio/dettaglio";
import { DettaglioSplitFormData } from "../../../../../../../interfaces/dettaglio/forms";
import { IDettaglio } from "../../../../../../../interfaces/dettaglio/models";
import Transition from "../../../../../../../lib/dialogTransition";
import { ISplitDialogProps, ISplitRow } from "../interfaces";
import SplitLastRow from "./splitLastRow";
import SplitRow from "./splitRow";

const i18_PATH = "prodotto.view.lottoDettagli.dialog.split.";

const checkNumberOfItems = (
  rows: ISplitRow[],
  selectedRow: ISplitRow,
  selectedItem: IDettaglio
): boolean =>
  selectedItem.pezzi ===
  rows.reduce((prev, row) => prev + (row.quantity ?? 0), 0) +
    (selectedRow.quantity ?? 0);

const rowCheck = (row: ISplitRow): boolean =>
  row.quantity !== null &&
  row.quantity >= 1 &&
  !!row.lastreDa &&
  !!row.lastreA &&
  row.quantity === row.lastreA - row.lastreDa + 1;

const checkNumbering = (
  rows: ISplitRow[],
  selectedRow: ISplitRow,
  selectedItem: IDettaglio
): boolean => {
  const rowsToCheck: ISplitRow[] = [...rows, selectedRow];
  if (!rowsToCheck.length) return false;
  const firstNumber = rowsToCheck[0].lastreDa;
  const lastNumber = rowsToCheck[rowsToCheck.length - 1].lastreA;
  if (
    !firstNumber ||
    !lastNumber ||
    selectedItem.pezzi !== lastNumber - firstNumber + 1
  )
    return false;
  let currentLastNumber;
  for (let row of rowsToCheck) {
    if (
      !rowCheck(row) ||
      !row.lastreDa ||
      !row.lastreA ||
      (currentLastNumber && row.lastreDa !== currentLastNumber + 1)
    ) {
      return false;
    }
    currentLastNumber = row.lastreA;
  }
  return true;
};

const checkValidity = (
  rows: ISplitRow[],
  selectedRow: ISplitRow,
  selectedItem: IDettaglio
): boolean => {
  const a = checkNumberOfItems(rows, selectedRow, selectedItem);
  const b = checkNumbering(rows, selectedRow, selectedItem);
  return a && b;
};

const updateRows = (rows: ISplitRow[], startIndex: number): void => {
  let lastSlab: number | null = null;
  for (let i = startIndex; i < rows.length; i++) {
    const row = rows[i];
    if (lastSlab) {
      row.lastreDa = lastSlab + 1;
      row.lastreA = lastSlab + (row.quantity ?? 0);
    }
    lastSlab = row.lastreA ?? 0;
  }
};

const updateRowsOnRemove = (rows: ISplitRow[], initSlab: number): void => {
  let lastSlab: number = initSlab - 1;
  rows.forEach((row) => {
    row.lastreDa = lastSlab + 1;
    row.lastreA = lastSlab + (row.quantity ?? 0);
    lastSlab = row.lastreA ?? 0;
  });
};

function DettaglioSplitDialog({
  isOpen,
  selectedItem,
  canSetCommercialOptions,
  onClose,
}: ISplitDialogProps) {
  const { t } = useTranslation();
  const [id, setId] = useState<number | null>(null);
  const [initSlab, setInitSlab] = useState<number>(1);
  const [rows, setRows] = useState<ISplitRow[]>([]);
  const [selectedRow, setSelectedRow] = useState<ISplitRow>({
    quantity: selectedItem.pezzi,
    misX: selectedItem.misX,
    misY: selectedItem.misY,
    misZ: selectedItem.misZ,
    lastreDa: selectedItem.lastreDa,
    lastreA: selectedItem.lastreA,
  });

  const [formData, setFormData] = useState<DettaglioSplitFormData>(null);

  const remainingQuantity = useMemo(
    () =>
      selectedItem?.pezzi -
      rows.reduce((prev, curr) => prev + (curr.quantity ?? 0), 0),
    [rows, selectedItem]
  );

  const isValid = useMemo(
    () => checkValidity(rows, selectedRow, selectedItem),
    [rows, selectedRow, selectedItem]
  );

  const onAddRow = () => {
    if (!rows.length) {
      setInitSlab(selectedRow.lastreDa ?? 1);
    }
    const lastRow = rows[rows.length - 1];
    const fromSlab = lastRow
      ? (lastRow.lastreA ?? 0) + 1
      : selectedRow.lastreDa ?? 1;
    const newRow = {
      quantity: 1,
      misX: rows[rows.length - 1]?.misX ?? selectedItem.misX ?? 0,
      misY: rows[rows.length - 1]?.misY ?? selectedItem.misY ?? 0,
      misZ: rows[rows.length - 1]?.misZ ?? selectedItem.misZ ?? 0,
      lastreDa: fromSlab,
      lastreA: fromSlab,
    };
    const _rows = [...rows, newRow];
    const remaining =
      selectedItem?.pezzi -
      _rows.reduce((prev, curr) => prev + (curr.quantity ?? 0), 0);
    setRows(_rows);
    setSelectedRow({
      ...selectedRow,
      lastreDa: fromSlab + 1,
      quantity: remaining,
    });
  };

  const onRemoveRow = (index: number) => {
    const _rows = rows.filter((_, i) => i !== index);
    updateRowsOnRemove(_rows, initSlab);
    const remaining =
      selectedItem?.pezzi -
      _rows.reduce((prev, curr) => prev + (curr.quantity ?? 0), 0);
    const last = _rows[_rows.length - 1]?.lastreA;
    const nextFrom = last ? last + 1 : selectedItem.lastreDa;
    setRows(_rows);
    setSelectedRow((s) => ({ ...s, quantity: remaining, lastreDa: nextFrom }));
  };

  const onUpdateRow = (index: number, row: ISplitRow) => {
    const _rows = [...rows];
    _rows[index] = row;
    updateRows(_rows, index);
    const remaining =
      selectedItem?.pezzi -
      _rows.reduce((prev, curr) => prev + (curr.quantity ?? 0), 0);
    const last = _rows[_rows.length - 1].lastreA ?? 0;
    setSelectedRow((s) => ({ ...s, quantity: remaining, lastreDa: last + 1 }));
    setRows(_rows);
  };

  const onUpdateSelectedRow = (row: ISplitRow) => {
    setSelectedRow(row);
  };

  const close = () => {
    setFormData(null);
    onClose();
  };

  const split = () => {
    setFormData({ rows, selectedRow });
  };

  useEffect(() => {
    setFormData(null);
    setRows([]);
    if (selectedItem) {
      setId(selectedItem.id);
      setSelectedRow({
        quantity: selectedItem.pezzi,
        misX: selectedItem.misX,
        misY: selectedItem.misY,
        misZ: selectedItem.misZ,
        lastreDa: selectedItem.lastreDa,
        lastreA: selectedItem.lastreA,
      });
    }
  }, [selectedItem, isOpen]);
  useDettaglioSplit(id, formData);
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="md"
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar variant="dense">
          <Typography variant="h5" sx={{ flex: 1 }} display="block">
            {t(`${i18_PATH}Title`)}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={close}
            aria-label="close"
          >
            <Icon className="fa-light fa-xmark" sx={{ overflow: "visible" }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          p: 2,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {rows.map((row, index) => (
          <SplitRow
            key={index}
            availableQuantity={remainingQuantity}
            row={row}
            removeRow={() => onRemoveRow(index)}
            updateRow={(row) => onUpdateRow(index, row)}
          ></SplitRow>
        ))}
        <Box sx={{ py: 1 }}>
          <Divider component="div" />
        </Box>
        <SplitLastRow
          row={selectedRow}
          splitted={!!rows.length}
          updateRow={onUpdateSelectedRow}
          addRow={onAddRow}
        ></SplitLastRow>
        {!isValid && (
          <Box>
            <Typography color="error">
              {t(`${i18_PATH}NumberingError`)}
            </Typography>
          </Box>
        )}
      </Box>
      <DialogActions>
        <Button onClick={close}>{t(`${i18_PATH}Cancella`)}</Button>
        <Button
          disabled={!canSetCommercialOptions || !isValid || !rows.length}
          variant="contained"
          color="primary"
          onClick={split}
        >
          {t(`${i18_PATH}Dividi`)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DettaglioSplitDialog;
